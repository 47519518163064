import React, { Component } from 'react';

class Footer1 extends Component {
    render() {
        return (
            <>
                <footer className="text-uppercase footer-bottom " style={{ backgroundColor: "#f2f2f2", padding: "0.5em 0px" }}>

                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 text-center text-white " > <span>Copyright © 2017 DevDisruptors</span> </div>

                        </div>
                    </div>

                </footer>
            </>
        );
    }
}

export default Footer1;

